<template>
  <div id="faq-articles-container" class="container">
    <b-row v-if="!error" class="content-row p-3 h-100 drop-shadow-box">
      <b-col id="titles-col" cols="4"
        ><b-row
          v-for="item in faqItems"
          :key="item.id"
          class="faq-title-row"
          no-gutters
          align-v="center"
          @click="handleItemSelect(item)"
          ><h4
            class="text-uppercase bold blue medium m-0"
            :class="[selectedItem === item ? 'selected' : '']"
          >
            {{ item.title }}
          </h4></b-row
        ></b-col
      ><b-col>
        <FAQDetail
          :item="selectedItem"
          :expanded="expanded"
          @toggleExpand="toggleExpand"
      /></b-col>

      <b-col id="faq-icon-col p-0" cols="2"
        ><div id="faq-icon-field" class="mx-auto position-relative pt-3">
          <img :src="iconImg" class="faq-icon position-absolute" />
          <img
            src="@/assets/img/settings/icon-help.svg"
            class="question-mark position-absolute"
          /></div
      ></b-col>
    </b-row>
    <ErrorPlaceholder v-else class="w-100" />
  </div>
</template>

<script>
import * as zenDeskAPI from "@/API/zendesk.js";

import ErrorPlaceholder from "@/components/base/error-placeholder.vue";
import FAQDetail from "@/components/faq/faq-detail.vue";
export default {
  name: "FAQArticlesContainer",
  components: { ErrorPlaceholder, FAQDetail },
  props: {
    sectionId: {
      type: [Number, String],
      required: true,
    },
    faqIcon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      faqItems: [],
      error: false,
      selectedItem: {},
      expanded: false,
    };
  },
  computed: {
    iconImg() {
      return require(`@/assets/img/nav-menu/nav-icon-${this.faqIcon}.png`);
    },
  },
  mounted: function () {
    zenDeskAPI
      .getArticlesFromSection(this.sectionId)
      .then((res) => {
        this.faqItems = res.data.articles;
        this.selectedItem = res.data.articles[0];
        this.$store.commit("setIsPageLoading", false);
      })
      .catch((e) => {
        console.log(e);
        this.error = true;
      });
  },
  methods: {
    handleItemSelect(item) {
      this.expanded = false;
      this.selectedItem = item;
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss">
#faq-articles-container {
  position: relative;
  z-index: 2;
  .content-row {
    background-image: linear-gradient(#267084, #050d1c);
    padding: 1px;
    .faq-title-row {
      padding: 16px 0 16px 0;
      cursor: pointer;
      &:hover h4 {
        filter: $hover-shadow;
      }
      &:not(:last-of-type) {
        border-bottom: 2px solid #253a48;
      }
      h4 {
        font-size: 24px;
        &.selected {
          color: white;
          text-shadow: 0 0 8px rgba(77, 229, 255, 0.7), 0 0 8px $light-blue;
        }
      }
    }

    #faq-icon-field {
      width: 120px;
      height: 120px;
      .faq-icon {
        left: 0;
        height: 100%;
      }
      .question-mark {
        width: 40%;
        right: 0;
        top: 25%;
        filter: drop-shadow(0 0 2px black);
      }
    }
  }
}
</style>
